@import 'normalize.css/normalize.css';

html {
  --color-fg: #828ab3;
  --color-white: #d0d0d0;
  --color-white-light: #fff;
  --color-black: #292d3e;
  --color-black-light: #434758;
  --color-Red: #f07178;
  --color-Red-light: #ff8b92;
  --color-green: #c3e88d;
  --color-green-light: #ddffa7;
  --color-yellow: #ffcb6b;
  --color-yellow-light: #ffe585;
  --color-blue: #82aaff;
  --color-blue-light: #9cc4ff;
  --color-magenta: #c792ea;
  --color-magenta-light: #e1acff;
  --color-cyan: #89ddff;
  --color-cyan-light: #a3f7ff;
  --color-white: #d0d0d0;
  --color-white-light: #ffffff;

  font-family: 'Source Code Pro', monospace;
  background-color: var(--color-black);
  color: var(--color-white);
  font-size: 16px;
}

button {
  border: 1px solid;
  font-weight: bold;
  border: 1px solid;
  background-color: transparent;
  padding: 5px 10px;
  border-radius: 1px;
  font-size: 0.875rem;

  &:not(:disabled) {
    border-color: var(--color-blue);
    cursor: pointer;
    color: var(--color-white);

    &:hover {
      border-color: var(--color-blue-light);
      background-color: var(--color-blue-light);
      color: var(--color-black);
    }
  }

  &+button {
    margin-left: 5px;
  }
}

* {
  box-sizing: border-box;
}

a {
  color: var(--color-magenta);
}